import React, { useState, useEffect, useRef } from 'react';

import {
	View,
	Platform,
	Text, Pressable, ImageBackground, Linking, Image, Share, StyleSheet,
	Animated, // Import Animated for smooth transitions
	useWindowDimensions	
} from 'react-native'

const Header = () => {
    // Create an animated value for the lower portion height of the header
    const animatedHeight = useRef(new Animated.Value(200)).current;

    useEffect(() => {
        // Animate lower portion height to 50 over 1 second to simulate rolling up effect
        Animated.timing(animatedHeight, {
            toValue: 50, // Final height
            duration: 3000, // Duration of the animation in milliseconds
            useNativeDriver: false, // Animating layout properties requires useNativeDriver to be false
        }).start();
    }, []);

	// Doing this instead of importing with static background pic
		let backgroundImage;
		try {
			backgroundImage = require(`../${process.env.REACT_APP_CATEGORY_BACKGROUND_PATH}`);
		} catch (error) {
			console.error('Error loading background image:', error);
			backgroundImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockLogoImage;
		try {
			nupeacockLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading nupeacock logo image:', error);
			nupeacockLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockTwitterLogoImage;
		try {
			nupeacockTwitterLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_TWITTER_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading twitter logo image:', error);
			nupeacockTwitterLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockFacebookLogoImage;
		try {
			nupeacockFacebookLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_FACEBOOK_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading facebook logo image:', error);
			nupeacockFacebookLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockInstagramLogoImage;
		try {
			nupeacockInstagramLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_INSTAGRAM_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading instagram logo image:', error);
			nupeacockInstagramLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockSocialshareLogoImage;
		try {
			nupeacockSocialshareLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_SOCIALSSHARE_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading socialshare logo image:', error);
			nupeacockSocialshareLogoImage = null; // Fallback in case the image can't be loaded
		}
	
		let nupeacockEmailLogoImage;
		try {
			nupeacockEmailLogoImage = require(`../${process.env.REACT_APP_NUPEACOCK_EMAIL_LOGO_PATH}`);
		} catch (error) {
			console.error('Error loading email logo image:', error);
			nupeacockEmailLogoImage = null; // Fallback in case the image can't be loaded
		}

    // Get the screen width using useWindowDimensions for responsive layout
    const { width } = useWindowDimensions();
    const isDesktopMode = width >= 1024;
		
	
		return (
			<ImageBackground source={backgroundImage} style={styles.imageBackground} resizeMode="cover">

				<View style={styles.headerContent}>
					<View style={styles.socialIcons}>
						<Pressable onPress={() => Linking.openURL('https://nupeacock.com')}>
							<Image source={nupeacockLogoImage} style={styles.icon} />
						</Pressable>
					</View>
					<View style={styles.headerText}>
						<Text style={[styles.title, isDesktopMode && styles.titleDesktop]}>
							{process.env.REACT_APP_SITE_NAME}
						</Text>
						<Text style={[styles.title, isDesktopMode && styles.titleDesktop]}>
							{process.env.REACT_APP_SITE_TEXT}
						</Text>
						<Text style={[styles.rider, isDesktopMode && styles.riderDesktop]}>
							**Always check for latest availabilies at store sites!
						</Text>
					</View>
					<View style={styles.socialIcons}>
						<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_TWITTER_URL)}>
						<Image source={nupeacockTwitterLogoImage} style={styles.icon} />
						</Pressable>
						<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_INSTAGRAM_URL)}>
						<Image source={nupeacockInstagramLogoImage} style={styles.icon} />
						</Pressable>
						<Pressable onPress={() => Linking.openURL(process.env.REACT_APP_FACEBOOK_URL)}>
						<Image source={nupeacockFacebookLogoImage} style={styles.icon} />
						</Pressable>
						<Pressable onPress={() => Linking.openURL('mailto:crengana@nupeacock.com')}>
						<Image source={nupeacockEmailLogoImage} style={styles.icon} />
						</Pressable>
						<Pressable onPress={sharePage}>
						<Image source={nupeacockSocialshareLogoImage} style={styles.icon} />
						</Pressable>
					</View>
				</View>
            	{/* Animated portion for the lower part of the background */}
            	<Animated.View style={[styles.animatedBottomSection, { height: animatedHeight }]}>
                	{/* Empty content for animated background */}
            	</Animated.View>
        	</ImageBackground>
		);
	};
	
	const sharePage = () => {
		const shareData = {
			title: process.env.REACT_APP_SITE_TITLE,
			text: process.env.REACT_APP_SITE_TEXT,
			url: process.env.REACT_APP_SITE_URL,
		};
		if (Platform.OS === 'web') {
			if (navigator.share) {
			navigator.share(shareData)
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing:', error));
			} else {
			console.log('Web Share API not supported.');
			}
		} else {
			Share.share(shareData)
			.then((result) => {
				if (result.action === Share.sharedAction) {
				console.log('Share was successful');
				}
			})
			.catch((error) => console.log('Error sharing:', error));
		}
	};

    const styles = StyleSheet.create({
		header: {
			width: '100%',
			padding: 10,
			alignItems: 'flex-start',
		},
		headerDesktop: {
			alignItems: 'center', // Center align on larger screens for a consistent look
		},
		imageBackground: {
			flex: 1,
			width: '100%',
			height: '100%',
		},
		headerContent: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
			width: '100%',
		},
		logo: {
			height: 60,
			flexDirection: 'row',
			justifyContent: 'flex-start',
		},
		icon: {
			height: 30,
			width: 30,
			marginLeft: 10,
			borderRadius: 25,
		},
		socialIcons: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			marginBottom: 20,
		},
		headerText: {
			flex: 1,
			marginLeft: 15,
		},
		title: {
			fontSize: 20,
			color: '#fff00f',
			fontWeight: 'bold',
			textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
		},
		titleDesktop: {
			fontSize: 32, // Larger title for desktop
		},
		subtitle: {
			fontSize: 18,
			color: '#ff0000',
			marginTop: 5,
			fontWeight: 'bold',
			textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
		},
		subtitleDesktop: {
			fontSize: 28, // Larger subtitle for desktop
		},
		rider: {
			fontSize: 10,
			color: '#ff0000',
			marginTop: 5,
			fontWeight: 'bold',
			textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
		},
		riderDesktop: {
			fontSize: 14, // Larger rider text for desktop
		},
    });        

export default Header;

