import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, 
  StyleSheet, TouchableOpacity, 
  ScrollView, Platform, 
  TextInput, Button,
  useWindowDimensions
} from 'react-native';

import { Chip } from 'react-native-paper'; // Use Chip for multiple selections

const SearchAndFilterPanel = ({ filters, onFilterChange, products, setFilteredProducts }) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [searchText, setSearchText] = useState('');

  // Add an array of new product filter options
  const newProductOptions = ['All', 'New'];

  // Extract unique categories and vendors from products
  const { categories, vendors, subCategories } = useMemo(() => {
  const categoriesSet = new Set(products.map(product => product.category).filter(Boolean));
  const vendorsSet = new Set(products.map(product => product.vendorName).filter(Boolean));
  const subCategoriesSet = new Set(products.map(product => product.subCategory).filter(Boolean));

 

  return {
      categories: Array.from(categoriesSet).sort(),
      vendors: Array.from(vendorsSet).sort(),
      subCategories: Array.from(subCategoriesSet).sort() // Add sub-categories
    };
  }, [products]);

  console.log(`in FilterPanel, subcategories`, subCategories)

  // Set default sub-category and vendor if not already set (default category is NA but just there from common code)
  useEffect(() => {
    if (categories.length > 0 && vendors.length > 0  && subCategories.length > 0 
      && filters.category.length === 0  && filters.vendor.length === 0 
      && filters.subCategory.length === 0 && (!filters.new || filters.new === '')) {
//      console.log(`default subcat in useeffct before onfiltechange`, subCategories[0])
//      console.log(`all categories in useeffct before onfiltechange`, subCategories)
      onFilterChange({
        ...filters,
        category: [categories[0]],
        vendor: [vendors[0]],
        subCategory: [subCategories[0]], // Set default sub-category
        new: 'All' // Set default new product filter
      });
    }
  }, [categories, vendors, subCategories, filters, onFilterChange]);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Handle Category and Vendor Changes - Category change not needed for per category sites, but just there, not used
  const handleCategoryChange = (category) => {
    const updatedCategories = filters.category.includes(category)
      ? filters.category.filter(c => c !== category)
      : [...filters.category, category];
    onFilterChange({ ...filters, category: updatedCategories });
  };

  const handleSubCategoryChange = (subCategory) => {
    const updatedSubCategories = filters.subCategory.includes(subCategory)
      ? filters.subCategory.filter((sc) => sc !== subCategory)
      : [...filters.subCategory, subCategory];
    onFilterChange({ ...filters, subCategory: updatedSubCategories });
  };
  
  const handleVendorChange = (vendor) => {
    const updatedVendors = filters.vendor.includes(vendor)
      ? filters.vendor.filter(v => v !== vendor)
      : [...filters.vendor, vendor];
    onFilterChange({ ...filters, vendor: updatedVendors });
  };

  // Handle Search
  const handleSearch = (text) => {
    setSearchText(text); // Update the search text state
    if (text.trim() === '') {
      // If the search text is cleared, revert to showing the filtered products based on selected filters
      const filtered = products.filter(product => {
        const matchesCategory = filters.category.length === 0 || filters.category.includes(product.category);
        const matchesVendor = filters.vendor.length === 0 || filters.vendor.includes(product.vendorName);
        const matchesSubCategory = filters.subCategory.length === 0 || filters.subCategory.includes(product.subCategory);
        return matchesCategory && matchesVendor && matchesSubCategory;
      });
      setFilteredProducts(filtered);
    } else {
      // Filter based on search text
      const filtered = products.filter((product) => {
        const matchesCategory = filters.category.length === 0 || filters.category.includes(product.category);
        const matchesVendor = filters.vendor.length === 0 || filters.vendor.includes(product.vendorName);
        const matchesSubCategory = filters.subCategory.length === 0 || filters.subCategory.includes(product.subCategory);
        const matchesSearch = (product['Product Name'] && product['Product Name'].toLowerCase().includes(text.toLowerCase())) ||
          (product['Product Description'] && product['Product Description'].toLowerCase().includes(text.toLowerCase()));
//        return matchesCategory && matchesVendor && matchesSearch;
        return matchesCategory && matchesVendor && matchesSubCategory && matchesSearch;

      });
      setFilteredProducts(filtered);
    }
  };

  // Handle Enter Key Press for Search
  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === 'Enter') {
      handleSearch();
    }
  };

  // Add a method to handle new product filter change
  const handleNewProductFilterChange = (newValue) => {
    onFilterChange({ ...filters, new: newValue });
  };

  const renderChipSection = (title, items, selectedItems, onItemChange) => (
    <View style={styles.section}>
      <TouchableOpacity
        style={styles.sectionHeader}
        onPress={() => toggleSection(title)}
      >
        <Text style={styles.sectionTitle}>{title}</Text>
        <Text style={styles.chevron}>
          {expandedSection === title ? '▼' : '▶'}
        </Text>
      </TouchableOpacity>
      {expandedSection === title && (
        <ScrollView style={styles.sectionContent} contentContainerStyle={styles.chipContainer}>
          {items.map((item, index) => (
            <Chip
              key={index}
              selected={selectedItems.includes(item)}
              onPress={() => onItemChange(item)}
              style={[
                styles.chip,
                selectedItems.includes(item) ? styles.selectedChip : null
              ]}
              textStyle={[
                styles.chipText,
                selectedItems.includes(item) ? styles.selectedChipText : null
              ]}
              selectedColor="#000000" // This will make the check icon black
            >
              {item}
            </Chip>
          ))}
        </ScrollView>
      )}
    </View>
  );

  const renderPriceSection = () => (
    <View style={styles.section}>
      <TouchableOpacity
        style={styles.sectionHeader}
        onPress={() => toggleSection('Max Price')}
      >
        <Text style={styles.sectionTitle}>Max Price</Text>
        <Text style={styles.chevron}>
          {expandedSection === 'Max Price' ? '▼' : '▶'}
        </Text>
      </TouchableOpacity>
      {expandedSection === 'Max Price' && (
        <View style={styles.sectionContent}>
          <TextInput
            style={styles.textInput}
            placeholder="Enter max price"
            value={filters.maxPrice}
            keyboardType="numeric"
            onChangeText={(value) => onFilterChange({ ...filters, maxPrice: value })}
          />
        </View>
      )}
    </View>
  );

 const renderSearchSection = () => (
    <View style={styles.section}>
      <View style={styles.searchHeader}>
        <Text style={styles.searchHeaderTitle}>🔍 Search</Text>
      </View>
      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          value={searchText}
          onChangeText={(text) => handleSearch(text)} // Trigger search dynamically
          placeholder="Search items in filtered products..."
          onKeyPress={handleKeyPress} // Trigger search on enter key press
        />
      </View>
    </View>
  );

  const renderNewProductSection = () => (
    <View style={styles.section}>
      <TouchableOpacity
        style={styles.sectionHeader}
        onPress={() => toggleSection('New Products')}
      >
        <Text style={styles.sectionTitle}>New Products</Text>
        <Text style={styles.chevron}>
          {expandedSection === 'New Products' ? '▼' : '▶'}
        </Text>
      </TouchableOpacity>
      {expandedSection === 'New Products' && (
        <ScrollView style={styles.sectionContent} contentContainerStyle={styles.chipContainer}>
          {newProductOptions.map((option, index) => (
            <Chip
              key={index}
              selected={filters.new === option}  // This should now work correctly
              onPress={() => handleNewProductFilterChange(option)}
              style={[
                styles.chip,
                filters.new === option ? styles.selectedChip : null
              ]}
              textStyle={[
                styles.chipText,
                filters.new === option ? styles.selectedChipText : null
              ]}
              selectedColor="#000000"
            >
              {option}
            </Chip>
          ))}
        </ScrollView>
      )}
    </View>
  );  

    // Get the screen width using useWindowDimensions for responsive layout
    const { width } = useWindowDimensions();
    const isDesktopMode = width >= 1024;

  return (
    <View style={[styles.container, isDesktopMode]}>
      <View style={[styles.header, isDesktopMode]}>
        <Text style={[styles.headerTitle, isDesktopMode]}>🔍 Filters</Text>
      </View>
      {/* Display Selected Filters */}
      <View style={[styles.selectedFiltersContainer, isDesktopMode]}>

{/* Category not needed for category sites like etsy, fithealth, etc, only sub-categories and vendors..actually even vendor NA for etsy but..
        <Text style={[styles.selectedFiltersTextTitle, isDesktopMode]}>Selected Categories:</Text>
        <Text style={[styles.selectedFiltersText, isDesktopMode]}>{filters.category.length > 0 ? filters.category.join(', ') : 'None'}</Text>
*/}
        <Text style={[styles.selectedFiltersTextTitle, isDesktopMode]}>Selected sub-Categories:</Text>
        <Text style={[styles.selectedFiltersText, isDesktopMode]}>{filters.subCategory.length > 0 ? filters.subCategory.join(', ') : 'None'}</Text>
        <Text style={[styles.selectedFiltersTextTitle, isDesktopMode]}>Selected Stores:</Text>
        <Text style={[styles.selectedFiltersText, isDesktopMode]}>{filters.vendor.length > 0 ? filters.vendor.join(', ') : 'None'}</Text>
        <Text style={[styles.selectedFiltersTextTitle, isDesktopMode]}>New Products:</Text>
        <Text style={[styles.selectedFiltersText, isDesktopMode]}>{filters.new || 'All'}</Text>        
        <Text style={[styles.selectedFiltersTextTitle, isDesktopMode]}>Max Price:</Text>
        <Text style={[styles.selectedFiltersText, isDesktopMode]}>{filters.maxPrice || 'None'}</Text>
      </View>

      <ScrollView>
{/* Category NA for per site cases
        {renderChipSection('Categories', categories, filters.category, handleCategoryChange)}
*/}
        {renderChipSection('Stores', vendors, filters.vendor, handleVendorChange)}
        {renderChipSection('Sub-Categories', subCategories, filters.subCategory, handleSubCategoryChange)}  {/* New sub-category section */}
        {renderNewProductSection()} {/* Add the new products filter section */}
        {renderPriceSection()}
        {renderSearchSection()}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'lightblue',
    padding: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 6,
    width: '100%',
    alignSelf: 'stretch',
    height: '100%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 2,
    borderBottomColor: '#6200ee',
    paddingBottom: 8,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1a1a1a',
  },
  selectedFiltersContainer: {
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    paddingVertical: 12,
  },
  selectedFiltersTextTitle: {
    fontSize: 20,
    color: 'black',
    fontWeight: 'bold',
  },
  selectedFiltersText: {
    marginBottom: 8,
    fontSize: 18,
    color: '#0000ff',
    fontWeight: '300',
  },
  section: {
    marginBottom: 16,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#333',
  },
  chevron: {
    fontSize: 12,
    color: 'crimson',
  },
  chip: {
    marginRight: 6,
    marginBottom: 6,
    height: 28,
    backgroundColor: 'white',
  },
  selectedChip: {
    backgroundColor: '#7B68EE',
  },
  chipText: {
    fontSize: 12,
    color: 'black',
    fontWeight: 'bold',
  },
  textInput: {
    borderWidth: 3,
    borderColor: 'black',
    borderRadius: 4,
    padding: 8,
    marginTop: 8,
    color: 'black',
    backgroundColor: 'white',
  },
  searchHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    borderBottomWidth: 2,
    borderBottomColor: '#6200ee',
    paddingBottom: 8,
  },
  searchHeaderTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1a1a1a',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 10,
    width: '100%',
  },
  searchInput: {
    borderWidth: 3,
    flex: 1,
    height: 40,
    borderColor: 'black',
    //borderWidth: 1,
    paddingHorizontal: 10,
    marginRight: 10,
    backgroundColor: 'white',
    borderRadius: 4,
  },
});

export default SearchAndFilterPanel;
