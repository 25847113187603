import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import sendToAnalytics from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<App />);


reportWebVitals(console.log)

//TODO
//reportWebVitals(sendToAnalytics);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
