/// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCr5tlw5HqKbX3M8DxUlRjZrhYhXp-eXQA",
  authDomain: "nu-1-a7369.firebaseapp.com",
  projectId: "nu-1-a7369",
  storageBucket: "nu-1-a7369.appspot.com",
  messagingSenderId: "743833284504",
  appId: "1:743833284504:web:9f35b6ac49e6def89d85ed",
  measurementId: "G-2SW4TEYEB5"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optionally get a reference to the storage service
const storage = getStorage(app);

// Optionally initialize Firebase Analytics
const analytics = getAnalytics(app);


const auth = getAuth(app);

// Initialize App Check
const appCheckInstance = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le7WmgqAAAAAA1dydQFhAEpXdjDjVN47Rwqr39d'),
  isTokenAutoRefreshEnabled: true
});

// Export the Firebase services you want to use
export { app, storage, analytics, appCheckInstance, auth };


